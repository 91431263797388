import colorbrewer from "colorbrewer";

const cols =
  "a.the_geom_webmercator, a.lad21cd, a.lad21nm, " +
  "b.doinla04, b.doinla09, b.doinla14, b.doinla19, b.doinla23, b.rdinla04, b.rdinla09, b.rdinla14, " +
  " b.rdinla19, b. rdinla23, b. rdoula04, b. rdoula09, b. rdoula14, b. rdoula19, b. rdoula23, b. remilaavep, b. remilaavpe, b. remilaavar, b. remilamepc, b. remilampep, b. remilamare, " +
  " b.remolaavep, b. remolaavpe, b.	remolaavar, b.	remolamepc, b.	remolampep, b. remolamare, " +
  " b.rewilaavep, b. rewilaavpe, b. rewilaavar, b. rewilamepc, b. rewilampep, b. rewilamare, " +
  " b.mime_1, b.mimep_1, b.mimarea_1, b.mimed_1, b.mimdep_1, b.mimdarea_1, " +
  " b.mime_2, b.mimep_2, b.mimarea_2, b.mimed_2, b.mimdep_2, b.mimdarea_2, " +
  " b.mime_3, b.mimep_3, b.mimarea_3, b.mimed_3, b.mimdep_3, b.mimdarea_3, " +
  " b.mime_4, b.mimep_4, b.mimarea_4, b.mimed_4, b.mimdep_4, b.mimdarea_4, " +
  " b.mime_5, b.mimep_5, b.mimarea_5, b.mimed_5, b.mimdep_5, b.mimdarea_5, " +
  " b.mome_1, b.momep_1, b.momarea_1, b.momed_1, b.momdep_1, b.momdarea_1, " +
  " b.mome_2, b.momep_2, b.momarea_2, b.momed_2, b.momdep_2, b.momdarea_2, " +
  " b.mome_3, b.momep_3, b.momarea_3, b.momed_3, b.momdep_3, b.momdarea_3, " +
  " b.mome_4, b.momep_4, b.momarea_4, b.momed_4, b.momdep_4, b.momdarea_4, " +
  " b.mome_5, b.momep_5, b.momarea_5, b.momed_5, b.momdep_5, b.momdarea_5, " +
  " b.wime_1, b.wimep_1, b.wimarea_1, b.wimed_1, b.wimdep_1, b.wimdarea_1, " +
  " b.wime_2, b.wimep_2, b.wimarea_2, b.wimed_2, b.wimdep_2, b.wimdarea_2, " +
  " b.wime_3, b.wimep_3, b.wimarea_3, b.wimed_3, b.wimdep_3, b.wimdarea_3, " +
  " b.wime_4, b.wimep_4, b.wimarea_4, b.wimed_4, b.wimdep_4, b.wimdarea_4, " +
  " b.wime_5, b.wimep_5, b.wimarea_5, b.wimed_5, b.wimdep_5, b.wimdarea_5";
const jointablesql = " a, rm_la21_combine b where a.lad21cd = b.lad21cd";

const ramp = colorbrewer.PiYG[11];
const legendStops = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0.001, colour: ramp[0], name: "Under 20 km" },
      { value: 20, colour: ramp[1], name: "20-30 km" },
      { value: 30, colour: ramp[2], name: "30-40 km" },
      { value: 40, colour: ramp[3], name: "40-50 km" },
      { value: 50, colour: ramp[4], name: "50-60 km" },
      { value: 60, colour: ramp[5], name: "60-70 km" },
      { value: 70, colour: ramp[6], name: "70-80 km" },
      { value: 80, colour: ramp[7], name: "80-90 km" },
      { value: 90, colour: ramp[8], name: "90-100 km" },
      { value: 100, colour: ramp[9], name: "100-120 km" },
      { value: 120, colour: ramp[10], name: "120 km+" }
    ]
  }
];

const rampEleChg = colorbrewer.RdBu[9];

const legendStopsEleChg = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: -999998, colour: rampEleChg[0], name: "15%+ worse" },
      { value: -15, colour: rampEleChg[1], name: "10-15% worse" },
      { value: -10, colour: rampEleChg[2], name: "5-10% worse" },
      { value: -5, colour: rampEleChg[3], name: "2-5% worse" },
      { value: -2, colour: rampEleChg[4], name: "<2% change" },
      { value: 2, colour: rampEleChg[5], name: "2-5% better" },
      { value: 5, colour: rampEleChg[6], name: "5-10% better" },
      { value: 10, colour: rampEleChg[7], name: "10-15% better" },
      { value: 15, colour: rampEleChg[8], name: "15%+ better" }
    ]
  }
];

const rampAreaChg6 = colorbrewer.RdBu[11];
const rampAreaChg = colorbrewer.RdBu[9];

const legend_epc_chg_mi = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: -999998, colour: rampAreaChg[0], name: "7+ decrease" },
      { value: -7, colour: rampAreaChg[1], name: "5-7 decrease" },
      { value: -5, colour: rampAreaChg[2], name: "3-5 decrease" },
      { value: -3, colour: rampAreaChg[3], name: "1-3 decrease" },
      { value: -1, colour: rampAreaChg[4], name: "<1 change" },
      { value: 1, colour: rampAreaChg[5], name: "1-3 increase" },
      { value: 3, colour: rampAreaChg[6], name: "3-5 increase" },
      { value: 5, colour: rampAreaChg[7], name: "5-7 increase" },
      { value: 7, colour: rampAreaChg[8], name: "7+ increase" }
    ]
  }
];

const legend_pepc_chg_moi = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: -999998, colour: rampAreaChg6[1], name: "3+ decrease" },
      { value: -3, colour: rampAreaChg6[2], name: "2-3 decrease" },
      { value: -2, colour: rampAreaChg6[3], name: "1-2 decrease" },
      { value: -1, colour: rampAreaChg6[4], name: "<1 decrease" },
      { value: 0, colour: rampAreaChg6[6], name: "<1 increase" },
      { value: 1, colour: rampAreaChg6[7], name: "1-2 increase" },
      { value: 2, colour: rampAreaChg6[8], name: "2-3 increase" },
      { value: 3, colour: rampAreaChg6[9], name: "3-4 increase" },
      { value: 4, colour: rampAreaChg6[10], name: "4+ increase" }
    ]
  }
];

const legend_floor_chg_all = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: -999998, colour: rampAreaChg6[0], name: "15 sqm+ smaller" },
      { value: -15, colour: rampAreaChg6[1], name: "11-15 sqm smaller" },
      { value: -11, colour: rampAreaChg6[2], name: "7.5-11 sqm smaller" },
      { value: -7.5, colour: rampAreaChg6[3], name: "4-7.5 sqm smaller" },
      { value: -4, colour: rampAreaChg6[4], name: "<4 sqm smaller" },
      { value: 0, colour: rampAreaChg6[6], name: "<4 sqm larger" },
      { value: 4, colour: rampAreaChg6[7], name: "4-7.5 larger" },
      { value: 7.5, colour: rampAreaChg6[8], name: "7.5-11 sqm larger" },
      { value: 11, colour: rampAreaChg6[9], name: "11-15 sqm larger" },
      { value: 15, colour: rampAreaChg6[10], name: "15 sqm+ larger" }
    ]
  }
];

const legend_epc_chg_mo = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: -999998, colour: rampAreaChg[0], name: "3+ decrease" },
      { value: -3, colour: rampAreaChg[2], name: "1-3 decrease" },
      { value: -1, colour: rampAreaChg[4], name: "<1 change" },
      { value: 1, colour: rampAreaChg[5], name: "1-3 increase" },
      { value: 3, colour: rampAreaChg[6], name: "3-5 increase" },
      { value: 5, colour: rampAreaChg[7], name: "5-7 increase" },
      { value: 7, colour: rampAreaChg[8], name: "7+ increase" }
    ]
  }
];

const legend_epc_chg_wi = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: -999998, colour: rampAreaChg[1], name: "3+ decrease" },
      { value: -3, colour: rampAreaChg[2], name: "1-3 decrease" },
      { value: -1, colour: rampAreaChg[4], name: "<1 change" },
      { value: 1, colour: rampAreaChg[6], name: "1-3 increase" },
      { value: 3, colour: rampAreaChg[7], name: "3-5 increase" },
      { value: 5, colour: rampAreaChg[8], name: "5+ increase" }
    ]
  }
];

const legend_pepc_chg_wi = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: -999998, colour: rampAreaChg6[3], name: "1+ decrease" },
      { value: -1, colour: rampAreaChg6[4], name: "<1 decrease" },
      { value: 0, colour: rampAreaChg6[6], name: "<1 increase" },
      { value: 1, colour: rampAreaChg6[7], name: "1-2 increase" },
      { value: 2, colour: rampAreaChg6[8], name: "2-3 increase" },
      { value: 3, colour: rampAreaChg6[9], name: "3-4 increase" },
      { value: 4, colour: rampAreaChg6[10], name: "4+ increase" }
    ]
  }
];

function getMetrics() {
  // prettier-ignore
  return [
    { id: 0, name: "Distance (Moves in, 2004)", field: "doinla04", display: "km", decimals: 0, legendOptions: [], legendStops: legendStops, additionalMetrics: [0, 1, 2, 3, 4] },
    { id: 1, name: "Distance (Moves in, 2009)", field: "doinla09", display: "km", decimals: 0, legendOptions: [], legendStops: legendStops, additionalMetrics: [0, 1, 2, 3, 4] },
    { id: 2, name: "Distance (Moves in, 2014)", field: "doinla14", display: "km", decimals: 0, legendOptions: [], legendStops: legendStops, additionalMetrics: [0, 1, 2, 3, 4] },
    { id: 3, name: "Distance (Moves in, 2019)", field: "doinla19", display: "km", decimals: 0, legendOptions: [], legendStops: legendStops, additionalMetrics: [0, 1, 2, 3, 4] },
    { id: 4, name: "Distance (Moves in, 2023)", field: "doinla23", display: "km", decimals: 0, legendOptions: [], legendStops: legendStops, additionalMetrics: [0, 1, 2, 3, 4] },
    { id: 5, name: "Deprivation Chg (Moves in, 2004)", field: "rdinla04", display: "%", decimals: 0, legendOptions: [], legendStops: legendStopsEleChg, additionalMetrics: [5, 6, 7, 8, 9] },
    { id: 6, name: "Deprivation Chg (Moves in, 2009)", field: "rdinla09", display: "%", decimals: 0, legendOptions: [], legendStops: legendStopsEleChg, additionalMetrics: [5, 6, 7, 8, 9] },
    { id: 7, name: "Deprivation Chg (Moves in, 2014)", field: "rdinla14", display: "%", decimals: 0, legendOptions: [], legendStops: legendStopsEleChg, additionalMetrics: [5, 6, 7, 8, 9] },
    { id: 8, name: "Deprivation Chg (Moves in, 2019)", field: "rdinla19", display: "%", decimals: 0, legendOptions: [], legendStops: legendStopsEleChg, additionalMetrics: [5, 6, 7, 8, 9] },
    { id: 9, name: "Deprivation Chg (Moves in, 2023)", field: "rdinla23", display: "%", decimals: 0, legendOptions: [], legendStops: legendStopsEleChg, additionalMetrics: [5, 6, 7, 8, 9] },
    { id: 10, name: "Deprivation Chg (Moves out, 2004)", field: "rdoula04", display: "%", decimals: 0, legendOptions: [], legendStops: legendStopsEleChg, additionalMetrics: [10, 11, 12, 13, 14] },
    { id: 11, name: "Deprivation Chg (Moves out, 2009)", field: "rdoula09", display: "%", decimals: 0, legendOptions: [], legendStops: legendStopsEleChg, additionalMetrics: [10, 11, 12, 13, 14] },
    { id: 12, name: "Deprivation Chg (Moves out, 2014)", field: "rdoula14", display: "%", decimals: 0, legendOptions: [], legendStops: legendStopsEleChg, additionalMetrics: [10, 11, 12, 13, 14] },
    { id: 13, name: "Deprivation Chg (Moves out, 2019)", field: "rdoula19", display: "%", decimals: 0, legendOptions: [], legendStops: legendStopsEleChg, additionalMetrics: [10, 11, 12, 13, 14] },
    { id: 14, name: "Deprivation Chg (Moves out, 2023)", field: "rdoula23", display: "%", decimals: 0, legendOptions: [], legendStops: legendStopsEleChg, additionalMetrics: [10, 11, 12, 13, 14] },
    { id: 15, name: "EPC Rating Chg Avg (Moves into,2009-23)", field: "remilaavep", display: "", decimals: 3, legendOptions: [], legendStops: legend_epc_chg_mi, additionalMetrics: [15, 16, 17, 18, 19, 20] },
    { id: 16, name: "EPC Rating Chg Avg (Moves into,2009-11)", field: "mime_1", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mi, additionalMetrics: [15, 16, 17, 18, 19, 20] },
    { id: 17, name: "EPC Rating Chg Avg (Moves into,2012-14)", field: "mime_2", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mi, additionalMetrics: [15, 16, 17, 18, 19, 20] },
    { id: 18, name: "EPC Rating Chg Avg (Moves into,2015-17)", field: "mime_3", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mi, additionalMetrics: [15, 16, 17, 18, 19, 20] },
    { id: 19, name: "EPC Rating Chg Avg (Moves into,2018-20)", field: "mime_4", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mi, additionalMetrics: [15, 16, 17, 18, 19, 20] },
    { id: 20, name: "EPC Rating Chg Avg (Moves into,2021-23)", field: "mime_5", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mi, additionalMetrics: [15, 16, 17, 18, 19, 20] },
    { id: 21, name: "EPC Rating Chg Avg (Moves out,2009-23)", field: "remolaavep", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mo, additionalMetrics: [21, 22, 23, 24, 25, 26] },
    { id: 22, name: "EPC Rating Chg Avg (Moves out,2009-11)", field: "mome_1", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mo, additionalMetrics: [21, 22, 23, 24, 25, 26] },
    { id: 23, name: "EPC Rating Chg Avg (Moves out,2012-14)", field: "mome_2", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mo, additionalMetrics: [21, 22, 23, 24, 25, 26] },
    { id: 24, name: "EPC Rating Chg Avg (Moves out,2015-17)", field: "mome_3", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mo, additionalMetrics: [21, 22, 23, 24, 25, 26] },
    { id: 25, name: "EPC Rating Chg Avg (Moves out,2018-20)", field: "mome_4", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mo, additionalMetrics: [21, 22, 23, 24, 25, 26] },
    { id: 26, name: "EPC Rating Chg Avg (Moves out,2021-23)", field: "mome_5", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mo, additionalMetrics: [21, 22, 23, 24, 25, 26] },
    { id: 27, name: "EPC Rating Chg Avg (Moves within,2009-23)", field: "rewilaavep", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_wi, additionalMetrics: [27, 28, 29, 30, 31, 32] },
    { id: 28, name: "EPC Rating Chg Avg (Moves within,2009-11)", field: "wime_1", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_wi, additionalMetrics: [27, 28, 29, 30, 31, 32] },
    { id: 29, name: "EPC Rating Chg Avg (Moves within,2012-14)", field: "wime_2", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_wi, additionalMetrics: [27, 28, 29, 30, 31, 32] },
    { id: 30, name: "EPC Rating Chg Avg (Moves within,2015-17)", field: "wime_3", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_wi, additionalMetrics: [27, 28, 29, 30, 31, 32] },
    { id: 31, name: "EPC Rating Chg Avg (Moves within,2018-20)", field: "wime_4", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_wi, additionalMetrics: [27, 28, 29, 30, 31, 32] },
    { id: 32, name: "EPC Rating Chg Avg (Moves within,2021-23)", field: "wime_5", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_wi, additionalMetrics: [27, 28, 29, 30, 31, 32] },
    { id: 33, name: "EPC Rating Chg Med (Moves into,2009-23)", field: "remilamepc", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mi, additionalMetrics: [33, 34, 35, 36, 37, 38] },
    { id: 34, name: "EPC Rating Chg Med (Moves into,2009-11)", field: "mimed_1", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mi, additionalMetrics: [33, 34, 35, 36, 37, 38] },
    { id: 35, name: "EPC Rating Chg Med (Moves into,2012-14)", field: "mimed_2", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mi, additionalMetrics: [33, 34, 35, 36, 37, 38] },
    { id: 36, name: "EPC Rating Chg Med (Moves into,2015-17)", field: "mimed_3", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mi, additionalMetrics: [33, 34, 35, 36, 37, 38] },
    { id: 37, name: "EPC Rating Chg Med (Moves into,2018-20)", field: "mimed_4", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mi, additionalMetrics: [33, 34, 35, 36, 37, 38] },
    { id: 38, name: "EPC Rating Chg Med (Moves into,2021-23)", field: "mimed_5", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mi, additionalMetrics: [33, 34, 35, 36, 37, 38] },
    { id: 39, name: "EPC Rating Chg Med (Moves out,2009-23)", field: "remolamepc", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mo, additionalMetrics: [39, 40, 41, 42, 43, 44] },
    { id: 40, name: "EPC Rating Chg Med (Moves out,2009-11)", field: "momed_1", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mo, additionalMetrics: [39, 40, 41, 42, 43, 44] },
    { id: 41, name: "EPC Rating Chg Med (Moves out,2012-14)", field: "momed_2", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mo, additionalMetrics: [39, 40, 41, 42, 43, 44] },
    { id: 42, name: "EPC Rating Chg Med (Moves out,2015-17)", field: "momed_3", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mo, additionalMetrics: [39, 40, 41, 42, 43, 44] },
    { id: 43, name: "EPC Rating Chg Med (Moves out,2018-20)", field: "momed_4", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mo, additionalMetrics: [39, 40, 41, 42, 43, 44] },
    { id: 44, name: "EPC Rating Chg Med (Moves out,2021-23)", field: "momed_5", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mo, additionalMetrics: [39, 40, 41, 42, 43, 44] },
    { id: 45, name: "EPC Rating Chg Med (Moves within,2009-23)", field: "rewilamepc", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_wi, additionalMetrics: [45, 46, 47, 48, 49, 50] },
    { id: 46, name: "EPC Rating Chg Med (Moves within,2009-11)", field: "wimed_1", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_wi, additionalMetrics: [45, 46, 47, 48, 49, 50] },
    { id: 47, name: "EPC Rating Chg Med (Moves within,2012-14)", field: "wimed_2", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_wi, additionalMetrics: [45, 46, 47, 48, 49, 50] },
    { id: 48, name: "EPC Rating Chg Med (Moves within,2015-17)", field: "wimed_3", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_wi, additionalMetrics: [45, 46, 47, 48, 49, 50] },
    { id: 49, name: "EPC Rating Chg Med (Moves within,2018-20f", field: "wimed_4", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_wi, additionalMetrics: [45, 46, 47, 48, 49, 50] },
    { id: 50, name: "EPC Rating Chg Med (Moves within,2021-23)", field: "wimed_5", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_wi, additionalMetrics: [45, 46, 47, 48, 49, 50] },
    { id: 51, name: "EPC Poten. Rating Chg Avg (Moves into,2009-23)", field: "remilaavpe", display: "", decimals: 3, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [51, 52, 53, 54, 55, 56] },
    { id: 52, name: "EPC Poten. Rating Chg Avg (Moves into,2009-11)", field: "mimep_1", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [51, 52, 53, 54, 55, 56] },
    { id: 53, name: "EPC Poten. Rating Chg Avg (Moves into,2012-14)", field: "mimep_2", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [51, 52, 53, 54, 55, 56] },
    { id: 54, name: "EPC Poten. Rating Chg Avg (Moves into,2015-17)", field: "mimep_3", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [51, 52, 53, 54, 55, 56] },
    { id: 55, name: "EPC Poten. Rating Chg Avg (Moves into,2018-20)", field: "mimep_4", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [51, 52, 53, 54, 55, 56] },
    { id: 56, name: "EPC Poten. Rating Chg Avg (Moves into,2021-23)", field: "mimep_5", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [51, 52, 53, 54, 55, 56] },
    { id: 57, name: "EPC Poten. Rating Chg Avg (Moves out,2009-23)", field: "remolaavpe", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [57, 58, 59, 60, 61, 62] },
    { id: 58, name: "EPC Poten. Rating Chg Avg (Moves out,2009-11)", field: "momep_1", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [57, 58, 59, 60, 61, 62] },
    { id: 59, name: "EPC Poten. Rating Chg Avg (Moves out,2012-14)", field: "momep_2", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [57, 58, 59, 60, 61, 62] },
    { id: 60, name: "EPC Poten. Rating Chg Avg (Moves out,2015-17)", field: "momep_3", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [57, 58, 59, 60, 61, 62] },
    { id: 61, name: "EPC Poten. Rating Chg Avg (Moves out,2018-20)", field: "momep_4", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [57, 58, 59, 60, 61, 62] },
    { id: 62, name: "EPC Poten. Rating Chg Avg (Moves out,2021-23)", field: "momep_5", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [57, 58, 59, 60, 61, 62] },
    { id: 63, name: "EPC Poten. Rating Chg Avg (Moves within,2009-23)", field: "rewilaavpe", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_wi, additionalMetrics: [63, 64, 65, 66, 67, 68] },
    { id: 64, name: "EPC Poten. Rating Chg Avg (Moves within,2009-11)", field: "wimep_1", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_wi, additionalMetrics: [63, 64, 65, 66, 67, 68] },
    { id: 65, name: "EPC Poten. Rating Chg Avg (Moves within,2012-14)", field: "wimep_2", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_wi, additionalMetrics: [63, 64, 65, 66, 67, 68] },
    { id: 66, name: "EPC Poten. Rating Chg Avg (Moves within,2015-17)", field: "wimep_3", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_wi, additionalMetrics: [63, 64, 65, 66, 67, 68] },
    { id: 67, name: "EPC Poten. Rating Chg Avg (Moves within,2018-20)", field: "wimep_4", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_wi, additionalMetrics: [63, 64, 65, 66, 67, 68] },
    { id: 68, name: "EPC Poten. Rating Chg Avg (Moves within,2021-23)", field: "wimep_5", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_wi, additionalMetrics: [63, 64, 65, 66, 67, 68] },
    { id: 69, name: "EPC Poten. Rating Chg Med (Moves into,2009-23)", field: "remilampep", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [69, 70, 71, 72, 73, 74] },
    { id: 70, name: "EPC Poten. Rating Chg Med (Moves into,2009-11)", field: "mimdep_1", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [69, 70, 71, 72, 73, 74] },
    { id: 71, name: "EPC Poten. Rating Chg Med (Moves into,2012-14)", field: "mimdep_2", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [69, 70, 71, 72, 73, 74] },
    { id: 72, name: "EPC Poten. Rating Chg Med (Moves into,2015-17)", field: "mimdep_3", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [69, 70, 71, 72, 73, 74] },
    { id: 73, name: "EPC Poten. Rating Chg Med (Moves into,2018-20)", field: "mimdep_4", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [69, 70, 71, 72, 73, 74] },
    { id: 74, name: "EPC Poten. Rating Chg Med (Moves into,2021-23)", field: "mimdep_5", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [69, 70, 71, 72, 73, 74] },
    { id: 75, name: "EPC Poten. Rating Chg Med (Moves out,2009-23)", field: "remolampep", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [75, 76, 77, 78, 79, 80] },
    { id: 76, name: "EPC Poten. Rating Chg Med (Moves out,2009-11)", field: "momdep_1", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [75, 76, 77, 78, 79, 80] },
    { id: 77, name: "EPC Poten. Rating Chg Med (Moves out,2012-14)", field: "momdep_2", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [75, 76, 77, 78, 79, 80] },
    { id: 78, name: "EPC Poten. Rating Chg Med (Moves out,2015-17)", field: "momdep_3", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [75, 76, 77, 78, 79, 80] },
    { id: 79, name: "EPC Poten. Rating Chg Med (Moves out,2018-20)", field: "momdep_4", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [75, 76, 77, 78, 79, 80] },
    { id: 80, name: "EPC Poten. Rating Chg Med (Moves out,2021-23)", field: "momdep_5", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [75, 76, 77, 78, 79, 80] },
    { id: 81, name: "EPC Poten. Rating Chg Med (Moves within,2009-23)", field: "rewilampep", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_wi, additionalMetrics: [81, 82, 83, 84, 85, 86] },
    { id: 82, name: "EPC Poten. Rating Chg Med (Moves within,2009-11)", field: "wimdep_1", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_wi, additionalMetrics: [81, 82, 83, 84, 85, 86] },
    { id: 83, name: "EPC Poten. Rating Chg Med (Moves within,2012-14)", field: "wimdep_2", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_wi, additionalMetrics: [81, 82, 83, 84, 85, 86] },
    { id: 84, name: "EPC Poten. Rating Chg Med (Moves within,2015-17)", field: "wimdep_3", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_wi, additionalMetrics: [81, 82, 83, 84, 85, 86] },
    { id: 85, name: "EPC Poten. Rating Chg Med (Moves within,2018-20)", field: "wimdep_4", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_wi, additionalMetrics: [81, 82, 83, 84, 85, 86] },
    { id: 86, name: "EPC Poten. Rating Chg Med (Moves within,2021-23)", field: "wimdep_5", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_wi, additionalMetrics: [81, 82, 83, 84, 85, 86] },
    { id: 87, name: "Floorspace Chg Avg (Moves into,2009-23)", field: "remilaavar", display: "sqm", decimals: 3, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [87, 88, 89, 90, 91, 92] },
    { id: 88, name: "Floorspace Chg Avg (Moves into,2009-11)", field: "mimarea_1", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [87, 88, 89, 90, 91, 92] },
    { id: 89, name: "Floorspace Chg Avg (Moves into,2012-14)", field: "mimarea_2", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [87, 88, 89, 90, 91, 92] },
    { id: 90, name: "Floorspace Chg Avg (Moves into,2015-17)", field: "mimarea_3", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [87, 88, 89, 90, 91, 92] },
    { id: 91, name: "Floorspace Chg Avg (Moves into,2018-20)", field: "mimarea_4", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [87, 88, 89, 90, 91, 92] },
    { id: 92, name: "Floorspace Chg Avg (Moves into,2021-23)", field: "mimarea_5", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [87, 88, 89, 90, 91, 92] },
    { id: 93, name: "Floorspace Chg Avg (Moves out,2009-23)", field: "remolaavar", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [93, 94, 95, 96, 97, 98] },
    { id: 94, name: "Floorspace Chg Avg (Moves out,2009-11)", field: "momarea_1", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [93, 94, 95, 96, 97, 98] },
    { id: 95, name: "Floorspace Chg Avg (Moves out,2012-14)", field: "momarea_2", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [93, 94, 95, 96, 97, 98] },
    { id: 96, name: "Floorspace Chg Avg (Moves out,2015-17)", field: "momarea_3", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [93, 94, 95, 96, 97, 98] },
    { id: 97, name: "Floorspace Chg Avg (Moves out,2018-20)", field: "momarea_4", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [93, 94, 95, 96, 97, 98] },
    { id: 98, name: "Floorspace Chg Avg (Moves out,2021-23)", field: "momarea_5", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [93, 94, 95, 96, 97, 98] },
    { id: 99, name: "Floorspace Chg Avg (Moves within,2009-23)", field: "rewilaavar", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [99, 100, 101, 102, 103, 104] },
    { id: 100, name: "Floorspace Chg Avg (Moves within,2009-11)", field: "wimarea_1", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [99, 100, 101, 102, 103, 104] },
    { id: 101, name: "Floorspace Chg Avg (Moves within,2012-14)", field: "wimarea_2", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [99, 100, 101, 102, 103, 104] },
    { id: 102, name: "Floorspace Chg Avg (Moves within,2015-17)", field: "wimarea_3", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [99, 100, 101, 102, 103, 104] },
    { id: 103, name: "Floorspace Chg Avg (Moves within,2018-20)", field: "wimarea_4", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [99, 100, 101, 102, 103, 104] },
    { id: 104, name: "Floorspace Chg Avg (Moves within,2021-23)", field: "wimarea_5", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [99, 100, 101, 102, 103, 104] },
    { id: 105, name: "Floorspace Chg Med (Moves into,2009-23)", field: "remilamare", display: "sqm", decimals: 3, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [105, 106, 107, 108, 109, 110] },
    { id: 106, name: "Floorspace Chg Med (Moves into,2009-11)", field: "mimdarea_1", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [105, 106, 107, 108, 109, 110] },
    { id: 107, name: "Floorspace Chg Med (Moves into,2012-14)", field: "mimdarea_2", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [105, 106, 107, 108, 109, 110] },
    { id: 108, name: "Floorspace Chg Med (Moves into,2015-17)", field: "mimdarea_3", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [105, 106, 107, 108, 109, 110] },
    { id: 109, name: "Floorspace Chg Med (Moves into,2018-20)", field: "mimdarea_4", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [105, 106, 107, 108, 109, 110] },
    { id: 110, name: "Floorspace Chg Med (Moves into,2021-23)", field: "mimdarea_5", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [105, 106, 107, 108, 109, 110] },
    { id: 111, name: "Floorspace Chg Med (Moves out,2009-23)", field: "remolamare", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [111, 112, 113, 114, 115, 116] },
    { id: 112, name: "Floorspace Chg Med (Moves out,2009-11)", field: "momdarea_1", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [111, 112, 113, 114, 115, 116] },
    { id: 113, name: "Floorspace Chg Med (Moves out,2012-14)", field: "momdarea_2", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [111, 112, 113, 114, 115, 116] },
    { id: 114, name: "Floorspace Chg Med (Moves out,2015-17)", field: "momdarea_3", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [111, 112, 113, 114, 115, 116] },
    { id: 115, name: "Floorspace Chg Med (Moves out,2018-20)", field: "momdarea_4", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [111, 112, 113, 114, 115, 116] },
    { id: 116, name: "Floorspace Chg Med (Moves out,2021-23)", field: "momdarea_5", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [111, 112, 113, 114, 115, 116] },
    { id: 117, name: "Floorspace Chg Med (Moves within,2009-23)", field: "rewilamare", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [117, 118, 119, 120, 121, 122] },
    { id: 118, name: "Floorspace Chg Med (Moves within,2009-11)", field: "wimdarea_1", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [117, 118, 119, 120, 121, 122] },
    { id: 119, name: "Floorspace Chg Med (Moves within,2012-14)", field: "wimdarea_2", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [117, 118, 119, 120, 121, 122] },
    { id: 120, name: "Floorspace Chg Med (Moves within,2015-17)", field: "wimdarea_3", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [117, 118, 119, 120, 121, 122] },
    { id: 121, name: "Floorspace Chg Med (Moves within,2018-20)", field: "wimdarea_4", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [117, 118, 119, 120, 121, 122] },
    { id: 122, name: "Floorspace Chg Med (Moves within,2021-23)", field: "wimdarea_5", display: "sqm", decimals: 0, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [117, 118, 119, 120, 121, 122] }
  ]
}

export const RESIDENTIALMOVES = {
  title: "Distances, Deprivation and EPC Changes in Residential Moves",
  description:
    "Residential mobility: Average distance of moves (DoRM), deprivation life measure changes following moves (RMD), and resulting changes recorded on EPCs (RMEPC). ",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/cdrc-residential-mobility-and-deprivation-rmd-index-lad-geography",
  storyLinks: [
    {
      link:
        "https://data.cdrc.ac.uk/stories/consumer-data-insights-housing-policy",
      title: "Consumer Data Insights for Housing Policy"
    }
  ],
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM la21_uk" + jointablesql,
  buildingSql: "SELECT " + cols + " FROM la21_uk_bua22" + jointablesql,
  detailUrbanSql: "SELECT " + cols + " FROM la21_uk_bua22" + jointablesql,
  urbanSql: "SELECT " + cols + " FROM la21_uk_bua22" + jointablesql,
  defaultLayers: "1111000",
  initialValue: 88,
  extent: 2,
  popup: false,
  idField: "lad21cd",
  geogNameField: "lad21nm",
  ladCodeField: "lad21cd",
  hoverFieldMetricMode: "Some",
  metrics: getMetrics()
};
